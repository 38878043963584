@charset "UTF-8";

@import "mixins";

.static.icon_num {
  margin-top: var(--margin-top-medium);
  padding-bottom: 20px;
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: center;
      gap: 4%;
    }
    .unit {
      text-align: center;
      margin-top: var(--space-large);
      border: 1px solid var(--color-border);
      padding: var(--space-small);
      @include mq(tb) {
        margin-top: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: var(--space-large);
      }
      &:first-of-type {
        margin-top: 0;
      }
      h3 {
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-bold);
        border-bottom: 1px solid var(--color-border);
        padding-bottom: var(--space-small);
        @include mq(tbl) {
          font-size: var(--font-size-large);
          padding-bottom: var(--space-medium);
        }
      }
      .icon {
        margin-top: var(--space-large);
        .image {
          @include mq(tb) {
            flex: 1;
          }
          img {
            max-width: 150px;
            max-height: 150px;
          }
        }
        .data {
          margin-top: var(--space-medium);
          display: flex;
          justify-content: center;
          align-items: flex-end;
          @include mq(tbl) {
            flex: 1;
          }
          em {
            display: block;
            color: var(--color-brand);
            font-size: 7rem;
            margin: 0 var(--space-xxsmall);
            @include mq(tb) {
              font-size: 6rem;
            }
            @include mq(tbl) {
              font-size: 7.4rem;
            }
          }
          span {
            font-size: var(--font-size-large);
            display: block;
            transform: translateY(-5px);
            font-weight: var(--font-weight-heading);
            font-family: var(--font-family-heading);
            @include mq(tbl) {
              font-size: var(--font-size-xlarge);
            }
          }
        }
      }
      .note {
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        margin-top: var(--space-medium);
        text-align: left;
        @include mq(tbl) {
          margin-top: var(--space-large);
          font-size: var(--font-size-small);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
