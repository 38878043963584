@charset "UTF-8";

@import "mixins";

.component.type_iframe{
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    text-align: center;
    iframe {
      max-width: 100%;
    }
  }
}
