@charset "UTF-8";

@import "mixins";

.navigation.pagenate {
  @include container;
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .m_newslist + & {
    .inner {
      padding-top: 0;
      border-top: none;
    }
  }
  .inner {
    a, span {
      text-align: center;
      display: block;
      width: var(--width-pagenate);
      height: var(--width-pagenate);
      line-height: 33px;
      border-radius: 0;
      border: 4px solid var(--color-brand-weak);
      font-size: var(--font-size-xsmall);
      color: var(--color-text);
      font-family: var(--font-family-number);
      font-weight: var(--font-weight-bold);
      @include mq(tbl) {
        width: var(--width-pagenate-pc);
        height: var(--width-pagenate-pc);
        line-height: 42px;
        font-size: var(--font-size-small);
        transition: border var(--transition-speed-fast) var(--bezier);
        &:hover {
          border-color: var(--color-brand-weaker);
        }
      }
      img {
        width: var(--width-pagenate-image);
        vertical-align: middle;
        @include mq(tbl) {
          width: var(--width-pagenate-image-pc);
        }
      }
      &.current, &.current_page {
        background-color: var(--color-brand-weak);
        color: var(--color-text-on-accent);
        font-weight: var(--font-weight-bold);
        @include mq(tbl) {
          &:hover {
            border-color:var(--color-brand-weak);
          }
        }
      }
      &.previouspostslink, &.nextpostslink, &.link_before, &.link_next {
        // background-color: transparent;
        border: none;
        @include mq(tbl) {
          @include hoverOp(.5);
        }
      }
      &.previouspostslink, &.link_before {
        img {
          transform: scale(-1, 1);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 var(--space-xxsmall) var(--space-small);
        @include mq(tbl) {
          margin: 0 var(--space-xsmall) var(--space-medium);
        }
      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 var(--space-xxsmall) var(--space-small);
        @include mq(tbl) {
          margin: 0 var(--space-xsmall) var(--space-medium);
        }
      }
    }
  }
}
