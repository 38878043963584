

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'spl': 'print, screen and (max-width: 768px) and (orientation:landscape)',
  'tb': 'print, screen and (min-width: 768px)',
  'tbl': 'print, screen and (min-width: 1080px)',
  'pc': 'print, screen and (min-width: 1280px)',
) !default;


@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
@mixin container($sp:var(--padding-sp),$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(tbl) {
    padding-left: calc(50% - (var(--width-container) / 2));
    padding-right: calc(50% - (var(--width-container) / 2));
  }
  @if $type == wide {
    @include mq(pc) {
      padding-left: calc(50% - (var(--width-container-wide) / 2));
      padding-right: calc(50% - (var(--width-container-wide) / 2));
    }
  }
  @if $type == narrow {
    @include mq(pc) {
      padding-left: calc(50% - (var(--width-container-narrow) / 2));
      padding-right: calc(50% - (var(--width-container-narrow) / 2));
    }
  }
}

@mixin hoverOp($op:0.7) {
  transition: opacity var(--transition-speed-fast) var(--bezier);
  &:hover {
    opacity: $op;
  }
}
@mixin hoverColor($color:var(--color-brand)) {
  transition: color var(--transition-speed-fast) var(--bezier);
  &:hover {
    color: $color;
  }
}
@mixin hoverBGColor($color:var(--color-brand)) {
  transition: background var(--transition-speed-fast) var(--bezier);
  &:hover {
    background-color: $color;
  }
}
@mixin fs($fts, $lin: 14,$ls:0) {
  $rem: calc($fts / 10);
  $linS: calc($lin / $fts);
  $lsm: calc($ls * 0.001);
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
  letter-spacing: #{$lsm}em;
  
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 5px;
    button {
      border: none;
      background-color: var(--color-surface-weaker);
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 0;
    }
    &.slick-active {
      button {
        background-color: var(--color-brand);
      }
    }
  }
}

@mixin grid($cols,$gap-vert,$gap-horiz) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: $gap-vert $gap-horiz;
}

@mixin caption {
  text-align: center;
  margin-top: var(--space-small);
  color: var(--color-text-weaker);
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-medium);
  @include mq(tbl) {
    font-size: var(--font-size-small);
  }
  &.shrink {
    margin-left: auto;
    margin-right: auto;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}
@mixin headingMedium {
  position: relative;
  padding-bottom: 10px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-medium);
  display: inline-block;
  @include mq(tbl) {
    padding-bottom: 14px;
    font-size: var(--font-size-large);
  }
  &:before {
    @include pd;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: var(--color-third);
    z-index: 2;
  }
}