@charset "UTF-8";

@import "mixins";

.component.type_column {
  margin-top: var(--margin-top-large);
  @include mq(tbl) {
    margin-top: var(--margin-top-xlarge);
  }
  .container {
    @include container;
    .inner {
      border: var(--border-width-column) solid var(--color-column-border);
      background-color: var(--color-column-bg);
      padding: var(--space-large);
      @include mq(tbl) {
        padding: var(--space-xxlarge);
        display: grid;
        grid-template-columns: var(--col-width-column-image) var(--col-width-column-text);
        grid-template-rows: auto auto 1fr;
      }
      &.textonly {
        display: block;
      }
      h3 {
        position: relative;
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-medium);
        padding-bottom: var(--space-medium);
        font-weight: var(--font-weight-heading);
        font-family: var(--font-family-heading);
        padding-top: var(--space-xxxsmall);
        padding-left: var(--space-xxlarge);
        position: relative;
        @include mq(tbl) {
          padding-bottom: var(--space-large);
          font-size: var(--font-size-xlarge);
          padding-top: var(--space-xxxsmall);
          padding-left: var(--space-xxxlarge);
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
        &::before {
          @include pd;
          background-color: var(--color-third);
          width: 36px;
          height: 36px;
          left: 0;
          top: 0;
          border-radius: var(--border-radius-circle);
          @include mq(tbl) {
            width: 46px;
            height: 46px;
          }
        }
      }
      .photo {
        padding-bottom: var(--space-medium);
        @include mq(tbl) {
          grid-column: 1 / 2;
          grid-row: 1 / 4;
          padding-right: var(--space-xlarge);
          padding-bottom: 0;
        }
        .child {
          margin-top: var(--space-medium);
          text-align: center;
          @include mq(tbl) {
            &:first-of-type {
              margin-top: 0;
            }
          }
          .image_sp {
            padding-bottom: 0;
            max-width: var(--max-width-column-image);
            margin-left: auto;
            margin-right: auto;
            @include mq(tbl) {
              display: none;
            }
          }
          .image_pc {
            display: none;
            padding-bottom: 0;
            @include mq(tbl) {
              display: inline;
            }
          }
          .caption {
            @include caption;
          }
        }
      }
      h4 {
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-bold);
        @include mq(tbl) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          font-size: var(--font-size-large);
        }
      }
      .text {
        padding-top: var(--space-medium);
        @include mq(tbl) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }
        .button {
          text-align: right;
        }
      }
    }
  }
  &.reverse {
    .container {
      .inner {
        @include mq(tbl) {
          grid-template-columns: var(--col-width-column-text) var(--col-width-column-image);
          grid-template-rows: auto auto 1fr;
        }
        h3 {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
          }
        }
        .photo {
          @include mq(tbl) {
            grid-column: 2 / 3;
            grid-row: 1 / 4;
            padding-right: 0;
            padding-left: var(--space-xlarge);
          }
        }
        h4 {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }
        }
        .text {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
          }
        }
      }
    }
  }
  &.vertical {
    .container {
      .inner {
        @include mq(tbl) {
          display: block;
        }
        .photo {
          @include mq(tbl) {
            padding: var(--space-xlarge) 0 var(--space-medium);
            display: flex;
            justify-content: center;
            gap: var(--gap-column-vertical);
          }
          .child {
            @include mq(tbl) {
              width: 100%;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  &.vertical_reverse {
    .container {
      .inner {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        h3 {
          grid-row: 1 / 2;
          grid-column: auto;
        }
        h4 {
          grid-row: 2 / 3;
          grid-column: auto;
        }
        .photo {
          grid-row: 4 / 5;
          grid-column: auto;
          padding-bottom: 0;
          margin-top: var(--space-medium);
          @include mq(tbl) {
            padding-right: 0;
            padding-left: 0;
            padding-bottom: var(--space-medium);
            margin-top: var(--space-xxlarge);
            padding-top: 0;
            display: flex;
            justify-content: center;
          }
          .child {
            @include mq(tbl) {
              width: 100%;
            }
          }
        }
        .text {
          grid-row: 3 / 4;
          grid-column: auto;
        }
      }
    }
  }
}
