@charset "UTF-8";

@import "mixins";

.component.type_movie_multi {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.set2 {
    .inner {
      @include grid(1,var(--gap-1col-col),0);
      @include mq(tbl) {
        @include grid(2,var(--gap-2col-col-pc),var(--gap-2col-row-pc));
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(3,var(--gap-3col-col-pc),var(--gap-3col-row-pc));
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(4,var(--gap-4col-col-pc),var(--gap-4col-row-pc));
      }
    }
  }
  .inner {
    @include container;
    .movie {
      .movie_wrap_outer {
        width: 100%;
        &.shrink {
          margin-left: auto;
          margin-right: auto;
        }
        .movie_wrap_inner {
          padding-top: calc(var(--aspect-ratio-movie) * 100%);
          position: relative;
          display: block;
          width: 100%;
          @include mq(tbl) {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
