@charset "UTF-8";
@import "mixins";

.toppage.lead {
  @include container;
  margin-top: var(--margin-top-medium);
  position: relative;
  @include mq(tbl) {
    margin-top: var(--margin-top-xlarge);
  }
  &::after {
    @include mq(tbl) {
      @include pd;
      background: center / contain no-repeat url('/images/logomark_single.svg');
      left: 48%;
      transform: translateX(calc(var(--width-container) / 2));
      width: 110px;
      height: 100px;
      bottom: -200px;
    }
  }
  + .component.type_heading {
    margin-top: var(--margin-top-xlarge);
    @include mq(tbl) {
      margin-top: 200px;
    }
  }
  .inner {
    border: 4px solid var(--color-accent-weak);
    padding: var(--space-large);
    @include mq(tb) {
      padding: var(--space-xxlarge);
    }
    @include mq(tbl) {
      padding: 74px 100px 80px 110px;
    }
    .title {
      text-align: right;
      h1 {
        writing-mode: vertical-rl;
        padding-right: var(--space-xxlarge);
        margin-left: auto;
        position: relative;
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-heading);
        letter-spacing: 2px;
        @include mq(tbl) {
          font-size: var(--font-size-large);
          padding-right: var(--space-xxxlarge);
          letter-spacing: 4px;
        }
        &::before {
          @include pd;
          width: 36px;
          height: 36px;
          right: 0;
          border-radius: var(--border-radius-circle);
          background-color: var(--color-third);
          @include mq(tbl) {
            width: 47px;
            height: 47px;
          }
        }
      }
    }
    .text {
      margin-top: var(--space-xxlarge);
      @include mq(tb) {
        margin-top: -40px;
      }
      @include mq(tbl) {
        margin-top: -80px;
      }
      h2 {
        font-size: var(--font-size-xxlarge);
        font-weight: var(--font-weight-heading);
        letter-spacing: 2px;
        @include mq(tb) {
          font-size: var(--font-size-xxxlarge);
        }
        @include mq(tbl) {
          font-size: 4.5rem;
          letter-spacing: 8px;
        }
      }
      .group {
        margin-top: var(--space-medium);
        font-size: var(--font-size-xxsmall);
        letter-spacing: 1px;
        @include mq(tb) {
          font-size: var(--font-size-xsmall);
        }
        @include mq(tbl) {
          margin-top: var(--space-xlarge);
          font-size: var(--font-size-medium);
        }
      }
      .desc {
        margin-top: var(--space-xlarge);
        @include mq(tbl) {
          margin-top: var(--space-xxxlarge);
        }
        p {
          font-size: var(--font-size-xsmall);
          line-height: 2;
          @include mq(tb) {
            font-size: var(--font-size-small);
            line-height: 2.4;
          }
          @include mq(tbl) {
            font-size: var(--font-size-medium);
          }
        }
      }
      .button {
        margin-top: var(--space-xlarge);
        @include mq(tbl) {
          margin-top: var(--space-xxxlarge);
        }
      }
    }
  }
}
.toppage.service_wrapper {
  background-color: #FFED7C;
  margin-top: var(--margin-top-xlarge);
  overflow: hidden;
  @include mq(tb) {
    padding-bottom: 80px;
  }
  @include mq(tbl) {
    margin-top: 180px;
  }
  .lead {
    padding: var(--space-xlarge);
    @include mq(tb) {
      padding: var(--space-xxlarge);
    }
    @include mq(tbl) {
      @include container;
      padding-top: 170px;
    }
    .title {
      text-align: right;
      @include mq(tbl) {
        transform: translateX(-47px);
      }
      h1 {
        writing-mode: vertical-rl;
        padding-right: var(--space-xxlarge);
        margin-left: auto;
        position: relative;
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-heading);
        letter-spacing: 2px;
        @include mq(tbl) {
          font-size: var(--font-size-large);
          padding-right: var(--space-xxxlarge);
          letter-spacing: 4px;
        }
        &::before {
          @include pd;
          width: 36px;
          height: 36px;
          right: 0;
          border-radius: var(--border-radius-circle);
          background-color: var(--color-accent-weak);
          @include mq(tbl) {
            width: 47px;
            height: 47px;
          }
        }
      }
    }
    .text {
      margin-top: var(--space-xxlarge);
      @include mq(tb) {
        margin-top: -40px;
      }
      @include mq(tbl) {
        margin-top: -160px;
      }
      h2 {
        font-size: var(--font-size-xlarge);
        font-weight: var(--font-weight-heading);
        letter-spacing: 2px;
        line-height: 2.2;
        margin-left: calc(var(--space-small) * -1);
        @include mq(tb) {
          font-size: var(--font-size-xxxlarge);
        }
        @include mq(tbl) {
          font-size: 4.5rem;
          letter-spacing: 8px;
        }
        span {
          padding: var(--space-small);
          background-color: var(--color-surface);
          @include mq(tb) {
            &:first-child {
              padding-right: 0;
            }
            &:nth-of-type(2) {
              padding-left: 0;
            }
          }
        }
      }
      .desc {
        margin-top: var(--space-xlarge);
        max-width: 570px;
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
        }
        p {
          font-size: var(--font-size-xsmall);
          line-height: 2;
          @include mq(tb) {
            font-size: var(--font-size-small);
            line-height: 2.4;
          }
          @include mq(tbl) {
            font-size: var(--font-size-medium);
          }
        }
      }
    }
  }
  .unit {
    margin-top: var(--margin-top-xsmall);
    padding-bottom: var(--space-xxlarge);
    @include mq(tb) {
      margin-top: var(--margin-top-medium);
    }
    @include mq(tbl) {
      display: flex;
      margin-top: 160px;
      @include container;
    }
    &:nth-of-type(even){
      .photo {
        @include mq(tbl) {
          transform: translateX(-50px);
        }
        img {
          @include mq(tbl) {
            width: 490px;
          }
        }
      }
      .text {
        .title {
          @include mq(tbl) {
            transform: translateY(-75px);
          }
        }
      }
    }
    &:nth-of-type(odd){
      @include mq(tbl) {
        flex-direction: row-reverse;
        margin-top: 120px;
      }
      .photo {
        text-align: right;
        @include mq(tbl) {
          transform-origin: left;
          transform: translateX(50px) scale(1.2);
        }
      }
      .text {
        @include mq(tbl) {
          flex-direction: row;
          padding-left:0;
          padding-right: 0;
        }
      }
    }
    .photo {
      @include mq(tbl) {
        flex: 1;
      }
      img {
        width: 100%;
        max-width: 800px;
      }
    }
    .text {
      padding-left: var(--padding-sp);
      padding-right: var(--padding-sp);
      padding-top: var(--space-xxxlarge);
      position: relative;
      @include mq(tb) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      @include mq(tbl) {
        flex: 1;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        max-width: none;
        padding-left: var(--space-large);
        padding-right: 0;
        padding-top: 0;
      }
      .title {
        writing-mode: vertical-rl;
        margin-left: auto;
        position: relative;
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-heading);
        position: absolute;
        top: -40px;
        right: 10%;
        letter-spacing: 2px;
        line-height: 2.4;
        @include mq(tb) {
          font-size: var(--font-size-xlarge);
          top: -60px;
          line-height: 2.2;
        }
        @include mq(tbl) {
          font-size: var(--font-size-xxlarge);
          padding-right: 0;
          letter-spacing: 4px;
          position: static;
          margin-left: 0;
        }
        span {
          background-color: var(--color-surface);
          padding: var(--space-xsmall);
          white-space: nowrap;
          @include mq(tbl) {
            padding: var(--space-medium) var(--space-xsmall);
          }
        }
      }
      .desc {
        p {
          font-size: var(--font-size-xsmall);
          line-height: 2;
          @include mq(tb) {
            font-size: var(--font-size-small);
          }
          @include mq(tbl) {
            font-size: var(--font-size-medium);
            margin-top: 150px;
            line-height: 2.4;
          }
        }
        .button {
          margin-top: var(--space-xlarge);
          @include mq(tbl) {
            margin-top: var(--space-xxlarge);
          }
          .p_button {
            font-size: var(--font-size-xsmall);
            padding: var(--space-small) var(--space-large);
            min-width: 180px;
            @include mq(tbl) {
              font-size: var(--font-size-medium);
              padding: var(--space-medium) var(--space-large);
              min-width: 230px;
            }
          }
        }
      }
    }
  }
}
.component.type_conversion.toppage {
  margin: 0;
  background-color: var(--color-accent-weak);
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  &::after {
    @include mq(tbl) {
      @include pd;
      background: center / contain no-repeat url('/images/logomark_single_white.svg');
      right: 48%;
      transform: translateX(calc((var(--width-container) / 2) * -1));
      width: 110px;
      height: 100px;
      top: -50px;
    }
  }
  @include mq(tbl) {
    padding-top: 100px;
    padding-bottom: 120px;
  }
  @include mq(pc) {
    padding-top: 160px;
    padding-bottom: 140px;
  }
  .inner {
    @include mq(tb) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    @include mq(tbl) {
      max-width: none;
    }
    .image {
      @include mq(tbl) {
        margin-top: -70px;
      }
      @include mq(pc) {
        margin-top: -100px;
      }
      img {
        max-width: 200px;
        @include mq(tbl) {
          max-width: 250px;
        }
      }
    }
    .copy {
      h4 {
        writing-mode: vertical-rl;
        margin-left: auto;
        margin-top: 0;
        position: relative;
        font-size: var(--font-size-xxlarge);
        font-weight: var(--font-weight-heading);
        letter-spacing: 2px;
        @include mq(tbl) {
          font-size: 4rem;
          padding-right: var(--space-xxxlarge);
          letter-spacing: 4px;
        }
        @include mq(pc) {
          font-size: 4.5rem;
          line-height: 2.0;
          letter-spacing: 12px;
        }
      }
    }
    .text {
      .desc {
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
        }
      }
    }
    .button {
      .p_button {
        border-color: var(--color-surface);
        background-color: transparent;
        color: var(--color-text-on-accent);
        padding-right: var(--space-xxlarge);
        @include mq(tbl) {
          padding: var(--space-large) var(--space-xxxlarge) var(--space-large) var(--space-xxlarge);
          font-size: var(--font-size-xlarge);
        }
        &:hover {
          @include mq(tbl) {
            border-color: var(--color-brand);
          }
        }
        &::before {
          background: center/contain no-repeat var(--arrow-button-on-accent);
          @include mq(tbl) {
            height: 20px;
          }
        }
      }
    }
  }
}