@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image_bg {
  margin-top: var(--margin-top-large);
  @include mq(tb) {
    @include container;
  }
  @include mq(tbl) {
    margin-top: var(--margin-top-xxlarge);
  }
  .inner {
    @include mq(tb) {
      position: relative;
    }
    .image {
      width: var(--width-catchcopy-image-bg-image);
      @include mq(tb) {
        width: var(--width-catchcopy-image-bg-image-pc);
      }
      img {
        aspect-ratio: var(--aspect-ratio-catchcopy-image);
        object-fit: cover;
      }
    }
    .text {
      background-color: var(--color-brand);
      padding: var(--space-xlarge);
      color: var(--color-text-on-accent);
      width: var(--width-catchcopy-image-bg-text);
      max-width: 400px;
      position: relative;
      margin-top: -15vw;
      margin-left: auto;
      @include mq(tb) {
        position: absolute;
        right: 0;
        margin-top: 0;
        bottom: 0;
        width: var(--width-catchcopy-image-bg-text-pc);
      }
      @include mq(tbl) {
        padding: var(--space-xxlarge);
      }
      h3 {
        @include headingMedium;
        display: inline-block;
        padding-bottom: 6px;
        font-size: var(--font-size-small);
        @include mq(tbl) {
          padding-bottom: 10px;
          font-size: var(--font-size-small);
        }
      }
      .copy {
        h4 {
          margin-top: var(--space-large);
          font-size: var(--font-size-xlarge);
          line-height: var(--line-height-medium);
          font-weight: var(--font-weight-heading);
          font-family: var(--font-family-heading);
          @include mq(tbl) {
            margin-top: var(--space-xlarge);
            font-size: var(--font-size-xxlarge);
          }
        }
      }
      .desc {
        margin-top: var(--space-small);
        @include mq(tbl) {
          margin-top: var(--space-medium);
        }
        p {
          @include mq(tbl) {
            font-size: var(--font-size-small);
          }

        }
      }
      .button {
        margin-top: var(--space-large);
        text-align: left;
        @include mq(tbl) {
          margin-top: var(--space-xlarge);
        }
        a {
          text-align: center;
        }
      }
    }
  }
  &.reverse {
    .inner {
      .image {
        margin-left: auto;
      }
      .text {
        margin-left: 0;
        margin-right: auto;
        @include mq(tb) {
          right: auto;
          left: 0;
        }
      }
    }
  }
}
