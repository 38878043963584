@charset "UTF-8";

@import "mixins";

.component.type_text_image {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: var(--space-large);
      @include mq(tb) {
        width: var(--col-width-text);
        margin-top: 0;
      }
    }
    .image {
      @include mq(tb) {
        width: var(--col-width-image);
        margin-top: 0;
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tb) {
        flex-direction: row-reverse;
      }
    }
  }
}
