@charset "UTF-8";

@import "mixins";

.component.type_extendedtitle {
  position: relative;
  background-color: var(--color-extendedtitle-bg);
  .inner {
    display: flex;
    flex-direction: column-reverse;
    z-index: 3;
    position: relative;
    @include mq(tb) {
      flex-direction: row;
      align-items: center;
    }
    .text {
      padding: 30px 20px;
      text-align: center;
      @include mq(tb) {
        width: 50%;
        text-align: left;
        padding: 20px 50px;
      }
      span {
        display: block;
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        color: var(--color-text-on-accent);
        font-family: var(--font-family-en);
        @include mq(tbl) {
          font-size: var(--font-size-medium);
          max-width: calc(var(--width-container-wide) / 2);
          margin-left: auto;
        }
      }
      h1 {
        position: relative;
        color: var(--color-text-on-accent);
        font-size: var(--font-size-xlarge);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-heading);
        font-family: var(--font-family-heading);
        @include mq(tbl) {
          max-width: calc(var(--width-container-wide) / 2);
          font-size: var(--font-size-xxxlarge);
          line-height: var(--line-height-medium);
          margin-left: auto;
        }
      }
    }
    .image {
      @include mq(tb) {
        width: 50%;
      }
      img {
        aspect-ratio: 71 / 40;
        object-fit: cover;
      }
    }
  }
}


