@charset "UTF-8";

@import "mixins";

main:has(.navigation.breadcrumbs) + footer{
  margin-top: 0;
}
footer {
  margin-top: var(--margin-top-medium);
  color: var(--color-text-on-accent);
  background-color: var(--color-bg-footer);
  @include mq(tbl) {
    margin-top: var(--margin-top-xlarge);
  }
  .inner {
    @include container($type:wide);
    .footinfo {
      padding-top: var(--padding-footer-top);
      @include mq(tbl) {
        padding-top: var(--padding-footer-top-pc);
        display: flex;
        justify-content: space-between;
      }
      .corpinfo {
        @include mq(tbl) {
          display: flex;
        }
        p {
          text-align: center;
          @include mq(tbl) {
            text-align: left;
          }
          img {
            width: var(--width-footer-logo);
            @include mq(tbl) {
              width: var(--width-footer-logo-pc);
            }
          }
        }
        .contact {
          margin-top: var(--space-large);
          text-align: center;
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            text-align: left;
            margin-top: 0;
            margin-left: var(--space-xxlarge);
            font-size: var(--font-size-small);
          }
          address {
            .zip {
              display: block;
              @include mq(tbl) {
                display: inline;
                margin-right: 1em;
              }
            }
          }
          ul {
            display: flex;
            justify-content: center;
            @include mq(tbl) {
              justify-content: flex-start;
            }
            li {
              margin-right: 1em;
              &:after {
                content: '|';
                padding-left: 1em;
              }
              &:last-child {
                margin-right: 0;
                &:after {
                  content: none;
                }
              }
            }
          }
        }
      }
      .sns {
        display: flex;
        justify-content: center;
        gap: var(--space-medium);
        margin-top: var(--space-large);
        @include mq(tbl) {
          margin-top: 0;
        }
        li {
          a {
            @include mq(tbl) {
              @include hoverOp(.5);
            }
            img {
              width: var(--width-footer-sns);
              @include mq(tbl) {
                width: var(--width-footer-sns-pc);
              }
            }
          }
        }
      }
    }
    .footnav {
      padding-top: var(--space-xxlarge);
      @include grid(2,var(--gap-footer-nav-col),var(--gap-footer-nav-row));
      text-align: center;
      @include mq(tbl) {
        padding-top: var(--space-xxxlarge);
        @include grid(4,var(--gap-footer-nav-col-pc),var(--gap-footer-nav-row-pc));
        text-align: left;
      }
      .unit {
        position: relative;
        h2 {
          position: relative;
          font-size: var(--font-size-small);
          line-height: var(--line-height-small);
          @include mq(tbl) {
            font-size: var(--font-size-medium);
          }
          a {
            color: var(--color-text-on-accent);
            @include hoverColor(var(--color-third));
          }
        }
        ul {
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-small);
          padding-top: var(--space-small);
          @include mq(tbl) {
            font-size: var(--font-size-small);
            padding-top: var(--space-medium);
          }
          + h2 {
            @include mq(tbl) {
              margin-top: var(--space-large);
            }
          }
          li {
            a {
              display: block;
              padding: var(--space-xxsmall) 0 ;
              position: relative;
              color: var(--color-text-on-accent);
              @include mq(tbl) {
                padding: var(--space-xsmall) 0;
                @include hoverColor(var(--color-third));
              }
            }
          }
        }
      }
    }
  }
  .copyright {
    margin-top: var(--space-xxlarge);
    @include container($type:wide);
    padding-bottom: var(--padding-footer-bottom);
    @include mq(tbl) {
      margin-top: var(--space-xxxlarge);
      padding-top: 0;
      padding-bottom: var(--padding-footer-bottom-pc);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row-reverse;
    }
    ul {
      display: flex;
      gap: var(--space-medium);
      @include mq(tbl) {
        gap: var(--space-large);
      }
      li {
        flex: 1;
        @include mq(tbl) {
          flex: auto;
        }
        a {
          display: block;
          text-align: center;
          color: var(--color-text-on-accent);
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-small);
          @include mq(tbl) {
            @include hoverColor(var(--color-third));
          }
        }
      }
    }
    p {
      margin-top: var(--space-large);
      font-size: var(--font-size-xxsmall);
      line-height: var(--line-height-medium);
      color: var(--color-text-on-accent);
      text-align: center;
      @include mq(tbl) {
        margin-top: 0;
        font-size: var(--font-size-xsmall);
      }
    }
  }
}

#pagetop {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  transition: opacity var(--transition-speed) var(--bezier);
  @include mq(tbl) {
    bottom: var(--space-xlarge);
    right: var(--space-large);
  }
  a {
    transition: opacity var(--transition-speed) var(--bezier);
    background-color: var(--color-bg-pagetop);
    background-image: var(--pagetop);
    background-size: var(--width-pagetop-pc) auto;
    width: var(--width-pagetop-pc);
    height: var(--width-pagetop-pc);
    @include mq(tbl) {
      display: block;
      &:hover {
        opacity: .5;
      }
    }
  }
  &.on {
    opacity: 1;
  }
}
