@charset "UTF-8";

@import "reset";

@import "mixins";

@import url("https://use.typekit.net/iks0ebh.css");

$responsive-debug-mode: false;

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: 62.5%;
  height: 100%;
}
body {
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: var(--font-family);
  letter-spacing: .02em;
  font-weight: normal;
  font-feature-settings: var(--font-feature-settings);
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @if $responsive-debug-mode {
    &:after {
      content: 'min';
      position: fixed;
      left: 10px;
      top: 10px;
      font-size: 12px;
      background-color: red;
      color: white;
      z-index: 1000;
      display: inline-block;
      padding: 4px;
      @include mq(sp) {
        content: 'sp';
      }
      @include mq(spl) {
        content: 'sp landscape';
      }
      @include mq(tb) {
        content: 'tablet';
      }
      @include mq(tbl) {
        content: 'tablet landscape';
      }
      @include mq(pc) {
        content: 'pc';
      }
    }
  }
  &.fixed {
    position: fixed;
    width: 100%;
  }
}

.red {
  color: var(--color-text-error);
}
strong {
  font-weight: var(--font-weight-bold);
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: var(--color-text-link);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &[href^="tel:"] {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}
abbr {
  text-decoration: none;
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  // width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @include mq(tbl) {
    // width: auto;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: 374px) {
    display: none;
  }
}
.hidden-tb {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden-tbl {
  @media (max-width: 1079px) {
    display: none;
  }
}

.anchor {
  position: relative;
  top: 0;
  display:block;
  visibility:hidden;
  color: transparent;
  &_sub {
    position: relative;
    top: 0;
    display:block;
    visibility:hidden;
    color: transparent;
    @include mq(tbl) {
      top: -100px;
    }
  }
}
.svg_def {
  visibility:hidden;
  width: 0;
  height: 0;
  position: absolute;
}
.container {
  @include container;
}
main {
  transition: opacity var(--transition-speed) var(--bezier);
  padding-top: var(--height-header);
  @include mq(tbl) {
    padding-top: var(--height-header-pc);
  }
  .toppage & {
    padding-top: 0;
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
}

.component .p_button, .p_button {
  display: inline-block;
  text-decoration: none;
  position: relative;
  line-height: 1;
  padding: var(--space-medium) var(--space-xlarge);
  min-width: var(--min-width-button);
  border-radius: var(--border-radius-pill);
  color: var(--color-text-on-accent);
  background: var(--color-brand);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-button);
  @include mq(tbl) {
    line-height: 1;
    font-size: var(--font-size-medium);
    min-width: var(--min-width-button-pc);
    border-radius: var(--border-radius-pill);
    transition: all var(--bezier) var(--transition-speed-fast);
  }
  &:before {
    @include pd;
    background: center / contain no-repeat var(--arrow-button-on-accent);
    width: 9px;
    height: 10px;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    @include mq(tbl) {
      right: 14px;
    }
  }
  &[target="_blank"] {
    &:before {
      background-image: var(--arrow-newwin);
      width: 13px;
      height: 12px;
    }
  }
  &.cv {
    line-height: 1;
    padding: var(--space-large) var(--space-xlarge);
    min-width: var(--min-width-button-apply);
    font-size: var(--font-size-medium);
    border-radius: var(--border-radius-pill);
    &:before {
      content: none;
    }
    @include mq(tbl) {
      min-width: var(--min-width-button-apply-pc);
      font-size: var(--font-size-large);
    }
  }
  &.reverse {
    color: var(--color-brand) !important;
    background: var(--color-surface);
    @include mq(tbl) {
      @include hoverBGColor(var(--color-surface-weaker));
    }
    &:before {
      background-image: var(--arrow-brand);
    }
    &[target="_blank"] {
      &:before {
        background-image: var(--arrow-newwin);
      }
    }
    &.cv {
      line-height: 1;
      padding: var(--space-large) var(--space-xlarge);
      min-width: var(--min-width-button-apply);
      font-size: var(--font-size-medium);
      border-radius: var(--border-radius-pill);
      &:before {
        content: none;
      }
      @include mq(tbl) {
        min-width: var(--min-width-button-apply-pc);
        font-size: var(--font-size-large);
      }
    }
  }
}

//default
@import "common/header";
@import "common/footer";
@import "component/index";
@import "navigation/index";
@import "static/index";
@import "top";
@import "uniq";
@import "visual";