@charset "UTF-8";

@import "mixins";

.component.type_anchor {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    ul {
      @include grid(2,var(--space-large),var(--space-medium));
      @include mq(tbl) {
        @include grid(5,var(--space-large), var(--space-large));
      }
      li {
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: var(--font-size-medium);
        }
        a {
          display: block;
          position: relative;
          text-decoration: none;
          color: var(--color-text);
          padding-left: 20px;
          transition: color var(--transition-speed-fast) var(--bezier);
          &:before {
            @include pd;
            height: 6px;
            width: 10px;
            left: 0;
            top: 7px;
            background-image: var(--arrow-bottom);
            background-repeat: no-repeat;
            background-size: contain;
            @include mq(tbl) {
              top: 10px;
            }
          }
          &:hover {
            @include mq(tbl) {
              color: var(--color-brand);
            }
          }
        }
      }
    }
  }
}
