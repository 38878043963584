@charset "UTF-8";

@import "mixins";

.navigation.localnav_sub {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .navigation.archivenav + & {
    margin-top: var(--margin-top-small);
    @include mq(tbl) {
      margin-top: var(--margin-top-medium);
    }
  }
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        margin-right: var(--space-small);
        margin-bottom: var(--space-small);
        white-space: nowrap;
        &.active,&.current_page_item {
          a {
            background-color: var(--color-brand-weak);
            color: var(--color-text-on-accent);
            font-weight: var(--font-weight-bold);
            @include mq(tbl) {
              &:hover {
                border-color: var(--color-brand-weak);
              }
            }
          }
        }
        a {
          display: inline-block;
          background-color: var(--color-surface);
          color: var(--color-text);
          font-size: var(--font-size-xsmall);
          padding: var(--space-xsmall) var(--space-small);
          border: 3px solid var(--color-brand-weak);
          border-radius: 0;
          text-decoration: none;
          @include mq(tbl) {
            font-size: var(--font-size-small);
            padding: var(--space-xsmall) var(--space-medium);
            transition: border var(--transition-speed-fast) var(--bezier);
            &:hover {
              border-color: var(--color-brand-weaker);
            }
          }
        }
      }
    }
  }
}
