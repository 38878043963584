@charset "UTF-8";

@import "mixins";

.footbanner {
  margin-top: 100px;
  @include mq(tbl) {
    display: flex;
    margin-top: 150px;
  }
  .localnav_bottom + & {
    margin-top: 0;
  }
  .toppage.articlelist + & {
    margin-top: 0;
  }
  .breadcrumbs + & {
    @include mq(tbl) {
      margin-top: 0;
    }
  }
  .unit {
    padding: 34px 26px;
    @include mq(tbl) {
      flex: 1;
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &.download {
      background: center / cover url(/images/bg_download.jpg);
    }
    &.contact {
      background: center / cover url(/images/bg_contact.jpg);
    }
    h2 {
      text-align: center;
      color: var(--color-text-on-accent);
      @include fs(18,30);
      @include mq(tbl) {
        @include fs(26,44);
      }
    }
    .button {
      margin-top: 22px;
      @include mq(tbl) {
        margin-top: 30px;
      }
      a {
        border-radius: 4px;
        padding: 17px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-text-on-accent);
        font-weight: var(--font-weight-bold);
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        @include mq(tbl) {
          padding: 25px;
          border-radius: 5px;
        }
        i {
          margin-right: 17px;
          svg {
            width: 28px;
            fill: var(--color-text-on-accent);
          }
        }
        span {
          @include fs(15,15);
          @include mq(tbl) {
            @include fs(18,18);
          }
        }
      }
      &.download {
        a {
          background-color: var(--color-accent);
        }
      }
      &.contact {
        a {
          background-color: var(--color-brand);
        }
      }
      &.tel {
        text-align: center;
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 20px;
        }
        a {
          background-color: var(--color-bg);
          display: block;
          color: var(--color-text);
          padding-top: 10px;
          padding-bottom: 8px;
          @include mq(tbl) {
            display: flex;
            padding: 20px;
          }
          .num {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--font-family-number);
            i {
              margin-right: 10px;
              @include mq(tbl) {
                margin-right: 15px;
              }
              img {
                width: 20px;
                transform: translateY(-2px);
                @include mq(tbl) {
                  width: 28px;
                  transform: translateY(-1px);
                }
              }
            }
            span {
              @include fs(24,24);
              @include mq(tbl) {
                @include fs(28,28);
              }
            }
          }
          .hours {
            color: #54565A;
            @include fs(12,18);
            @include mq(tbl) {
              @include fs(13,18);
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}