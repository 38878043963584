@charset "UTF-8";

@import "mixins";

.component.type_speaker_heading {
  position: relative;
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
    width: var(--width-container);
    margin-left: auto;
    margin-right: auto;
  }
  &.reverse {
    .inner {
      .card {
        margin-left: auto;
        text-align: right;
        @include mq(tbl) {
          margin-right: calc(2 * var(--space-xxlarge));
        }
        .name {
          text-indent: 0;
        }
      }
    }
  }
  .image {
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      object-fit: cover;
      @include mq(tbl) {
        height: auto;
      }
    }
  }
  .inner {
    padding: 0 var(--padding-sp);
    @include mq(tbl) {
      padding: 0;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: var(--width-container);
      display: flex;
      align-items: center;
    }
    .card {
      background-color: var(--color-brand);
      color: var(--color-text-on-accent);
      margin-top: calc( -1 * var(--margin-top-large));
      position: relative;
      z-index: 2;
      padding: var(--space-xlarge);
      max-width: 330px;
      @include mq(tbl) {
        margin-top: 0;
        margin-left: calc(2 * var(--space-xxlarge));
        padding: var(--space-xxlarge);
        max-width: none;
      }
      h2 {
        font-size:var(--font-size-xlarge);
        line-height: var(--line-height-medium);
        font-family: var(--font-family-heading);
        font-weight: var(--font-weight-heading);
        @include mq(tbl) {
          font-size:var(--font-size-xxlarge);
        }
      }
      .position {
        margin-top: var(--space-large);
        font-size:var(--font-size-small);
        line-height: var(--line-height-small);
      }
      .name {
        font-size:var(--font-size-xlarge);
        margin-top: var(--space-small);
        font-family: var(--font-family-heading);
        font-weight: var(--font-weight-heading);
        text-indent: -.1em;
        @include mq(tbl) {
          font-size:var(--font-size-xxlarge);
        }
      }
      .en {
        font-family: var(--font-family-en);
        font-size:var(--font-size-xsmall);
        margin-top: var(--space-xsmall);
        font-weight: var(--font-weight-bold);
        opacity: .4;
      }
    }
  }
}
