@charset "UTF-8";

@import "mixins";

.component.type_image_multi {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.set1 {
    .inner {
      @include mq(tb) {
        justify-content: center;
      }
    }
  }
  &.set2 {
    .inner {
      @include grid(1,var(--gap-1col-col),0);
      @include mq(tbl) {
        @include grid(2,var(--gap-2col-col-pc),var(--gap-2col-row-pc));
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(3,var(--gap-3col-col-pc),var(--gap-3col-row-pc));
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,var(--gap-2col-col),var(--gap-2col-row));
      @include mq(tbl) {
        @include grid(4,var(--gap-4col-col-pc),var(--gap-4col-row-pc));
      }
    }
  }
  .inner {
    @include container;
  }
}
