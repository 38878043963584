@charset "UTF-8";

@import "mixins";


.component.type_pagetitle {
  .m_breadcrumbs + & {
    margin-top: 10px;
    @include mq(tbl) {
      margin-top: 18px;
    }
  }
  .inner {
    @include container;
    position: relative;
    padding-top: var(--margin-top-medium);
    @include mq(tbl) {
      padding-top: var(--margin-top-large);
    }
    .title {
      position: relative;
      font-size: var(--font-size-xlarge);
      line-height: var(--line-height-medium);
      font-weight: var(--font-weight-heading);
      font-family: var(--font-family-heading);
      @include mq(tbl) {
        font-size: var(--font-size-xxxlarge);
        line-height: var(--line-height-medium);
      }
    }
  }
}
