@charset "UTF-8";

@import "mixins";

.component.type_faq {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .unit {
      border-top: 1px solid var(--color-border);
      &:first-of-type {
        border-width: 1px;
      }
      &:last-of-type {
        border-bottom: 1px solid var(--color-border);
      }
      .q {
        padding: var(--space-large) var(--space-xlarge);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        position: relative;
        @include mq(tbl) {
          padding: var(--space-xlarge) var(--space-xxxlarge);
          font-size: var(--font-size-medium);
          cursor: pointer;
          &:hover {
            color: var(--color-brand);
          }
        }
        &.active {
          &:after {
            transform: rotate(180deg);
          }
        }
        &:after {
          @include pd;
          width: 15px;
          height: 15px;
          background: center / contain no-repeat var(--arrow-faq);
          right: 15px;
          top: 50%;
          margin-top: -7px;
          transition: transform var(--transition-speed-fast) var(--bezier);
          @include mq(tbl) {
            width: 18px;
            height: 18px;
            margin-top: -9px;
            right: 30px;
          }
        }
        &:before {
          @include pd;
          content: 'Q.';
          top: var(--top-faq-q);
          left: var(--left-faq-q);
          font-size: var(--font-size-large);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            font-size: var(--font-size-xxlarge);
            left: var(--left-faq-q-pc);
            top: var(--top-faq-q-pc);
          }
        }
      }
      .a {
        padding: 0 var(--space-xlarge) var(--space-large);
        font-size: var(--font-size-small);
        line-height: var(--line-height-medium);
        position: relative;
        display: none;
        @include mq(tbl) {
          padding: 0 var(--space-xxxlarge) var(--space-xlarge);
          font-size: var(--font-size-medium);
        }
        &:before {
          @include pd;
          content: 'A.';
          top: var(--top-faq-a);
          left: var(--left-faq-a);
          font-size: var(--font-size-large);
          line-height: var(--line-height-medium);
          color: var(--color-brand);
          @include mq(tbl) {
            font-size: var(--font-size-xxlarge);
            left: var(--left-faq-a-pc);
            top: var(--top-faq-a-pc);
          }
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          display: block;
          margin: 0 auto var(--space-small) !important;
          &.mt-image-right {
            float: none !important;
            @include mq(tbl) {
              float: right !important;
              margin: 0 0 var(--space-large) var(--space-large) !important;
            }
          }
          &.mt-image-left {
            float: none !important;
            @include mq(tbl) {
              float: left !important;
              margin: 0 var(--space-large) var(--space-large) 0 !important;
            }
          }
        }
        p {
          padding-bottom: var(--space-large);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
