@charset "UTF-8";

@import "mixins";

.navigation.breadcrumbs {
  margin-top: var(--margin-top-xlarge);
  padding-bottom: var(--space-medium);
  padding-top: var(--space-medium);
  border-top: 1px solid var(--color-border);
  @include mq(tbl) {
    margin-top: var(--margin-top-xxlarge);
    padding-bottom: var(--space-large);
    padding-top: var(--space-large);
  }
  .m_localnav_bottom + & {
    margin-top: 0;
  }
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        color: var(--color-text);
        position: relative;
        margin-right: 3px;
        font-size: var(--font-size-xxsmall);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: var(--font-size-xsmall);
        }
        &:after {
          content: '\FF0F';
          margin-left: 3px;
          color: var(--color-text-weaker);
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        a {
          color: var(--color-text-weaker);
          @include hoverColor;
          text-decoration: underline;
        }
      }
    }
  }
}
