@charset "UTF-8";

@import "mixins";

.component.type_speaker {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: flex-start;
    }
    .speaker {
      background-color: var(--color-speaker-bg);
      padding: var(--space-large);
      color: var(--color-speaker-text);
      @include mq(tbl) {
        position: sticky;
        top: 0;
        width: var(--col-width-speaker-panel);
        margin-top: var(--space-large);
        padding: var(--space-large);
        &.static {
          position: static;
        }
      }
      .heading {
        font-size: var(--font-size-xsmall);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-medium);
        color: var(--color-third);
        @include mq(tbl) {
          font-size: var(--font-size-small);
        }
      }
      .unit {
        margin-top: var(--space-medium);
        @include mq(tbl) {
          margin-top: var(--space-large);
        }
        + .unit {
          margin-top: var(--space-xlarge);
          @include mq(tbl) {
            margin-top: var(--space-xxlarge);
          }
        }
        .name {
          margin-top: var(--space-medium);
          dt {
            font-size: var(--font-size-large);
            line-height: var(--line-height-medium);
            font-weight: var(--font-weight-heading);
            font-family: var(--font-family-heading);
            @include mq(tbl) {
              font-size: var(--font-size-xlarge);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          dd {
            font-size: var(--font-size-xsmall);
            font-weight: var(--font-weight-bold);
            opacity: .4;
          }
        }
        .profile {
          margin-top: var(--space-medium);
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-medium);
        }
      }
    }
    .text {
      margin-top: var(--space-large);
      @include mq(tbl) {
        width: var(--col-width-speaker-content);
        margin-top: 0;
      }
      .float_image {
        @include clearfix;
        padding-bottom: var(--space-xlarge);
        figure {
          float: right;
          width: var(--col-width-float-image);
          padding-left: var(--gap-float-image);
          padding-top: var(--space-xsmall);
          padding-bottom: var(--space-xsmall);
          figcaption {
            text-align: center;
            margin-top: var(--space-xxsmall);
            color: var(--color-text-weaker);
            font-size: var(--font-size-xsmall);
            line-height: var(--line-height-medium);
            @include mq(tbl) {
              margin-top: var(--space-xsmall);
              font-size: var(--font-size-small);
            }
          }
        }
      }
      .component {
        margin-top: var(--space-medium);
        padding-bottom: var(--space-large);
        @include mq(tbl) {
          margin-top: var(--space-large);
          padding-bottom: var(--space-xxlarge);
        }
        .container {
          padding: 0;
          display: block;
          .inner {
            .text {
              margin-top: 0;
              width: 100%;
            }
          }
        }
      }
      .type_table {
        padding-left: 0;
        padding-right: 0;
        .inner {
          padding: 0;
          display: block;
        }
      }
    }
  }
}
