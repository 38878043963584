@charset "UTF-8";

@import "mixins";

.component.type_wysiwyg {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
  }
}
