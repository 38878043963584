@charset "UTF-8";

@import "mixins";

.component.type_catalog {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .list {
      margin-top: var(--gap-catalog-col);
      @include grid(1,var(--gap-catalog-col),0);
      @include mq(tb) {
        @include grid(3,0,var(--gap-catalog-row-pc));
        padding-bottom: var(--space-xlarge);
        border-bottom: 1px solid var(--color-border);
      }
      .unit {
        position: relative;
        @include mq(tb) {
          display: grid;
          grid-template-rows: subgrid;
          grid-row: span 2;
          gap: 0;
        }
        &:before {
          @include pd();
          @include mq(tb) {
            width: 1px;
            height: 100%;
            right: calc(var(--gap-catalog-row-pc) / 2 * -1);
            background-color: var(--color-border);
          }
        }
        &:nth-child(3n){
          &:before {
            content: none;
          }
        }
        .main {
          display: flex;
          justify-content: space-between;
          @include mq(tb) {
            display: block;
          }
          .image {
            width: var(--col-width-catalog-image);
            @include mq(tb) {
              width: 100%;
            }
            a {
              display: block;
              @include mq(tb) {
                aspect-ratio: var(--aspect-ratio-catalog-pc);
                display: flex;
                justify-content: center;
                align-items: center;
              }
              @include mq(tbl) {
                @include hoverOp;
              }
            }
            img {
              border-radius: 0;
              @include mq(tb) {
                max-height: 100%;
              }
            }
          }
          .desc {
            width: var(--col-width-catalog-text);
            @include mq(tb) {
              width: auto;
            }
            .title {
              padding-bottom: 0;
              font-size: var(--font-size-medium);
              line-height: var(--line-height-medium);
              font-weight: var(--font-weight-bold);
              @include mq(tb) {
                margin-top: var(--space-large);
              }
              @include mq(tbl) {
                @include hoverOp;
              }
              a {
                color: var(--color-text);
                position: relative;
                text-decoration: none;
                word-break: break-all;
              }
            }
            .subtitle {
              color: var(--color-text-weaker);
              font-size: var(--font-size-xsmall);
              margin-top: var(--space-xxsmall);
              line-height: var(--line-height-medium);
              @include mq(tbl) {
                font-size: var(--font-size-small);
              }
            }
            .format {
              font-size: var(--font-size-xxsmall);
              margin-top: var(--space-xxsmall);
              @include mq(tbl) {
                font-size: var(--font-size-xsmall);
                margin-top: var(--space-small);
              }
              img {
                width: 20px;
                height: 22px;
                object-fit: contain;
                margin-right: 8px;
                vertical-align: middle;
              }
              span {
                text-transform: uppercase;
                letter-spacing: 0;
              }
              a {
                color: var(--color-text-weaker);
                text-decoration: none;
                @include mq(tbl) {
                  @include hoverOp;
                }
              }
            }
          }
        }
        .summary {
          margin-top: var(--space-small);
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            font-size: var(--font-size-small);
          }
        }
      }
    }
  }
}
