@charset "UTF-8";

@import "mixins";

.component.type_catchcopy {
  margin-top: var(--margin-top-large);
  @include mq(tbl) {
    margin-top: var(--margin-top-xxlarge);
  }
  &.withbg {
    padding-bottom: var(--padding-bottom-catchcopy);
    background-repeat: no-repeat;
    background-position: right bottom;
    @include mq(tbl) {
      padding-bottom: var(--padding-bottom-catchcopy-pc);
    }
    + * {
      margin-top: 0;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: var(--gap-2col-row-pc);
      grid-row-gap: 0;
    }
    .heading {
      @include mq(tb) {
        grid-area: 1 / 1 / 2 / 3;
      }
      h3 {
        @include headingMedium;
      }
    }
    .copy {
      @include mq(tb) {
        grid-area: 2 / 1 / 3 / 2;
      }
      h4 {
        margin-top: var(--space-large);
        font-size: var(--font-size-xlarge);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-heading);
        font-family: var(--font-family-heading);
        @include mq(tbl) {
          font-size: var(--font-size-xxxlarge);
        }
      }
      .button {
        display: none;
        margin-top: var(--space-xxxlarge);
        @include mq(tb) {
          display: block;
        }
        a {
          text-align: center;
        }
      }
    }
    .text {
      margin-top: var(--space-xlarge);
      @include mq(tb) {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
    .button {
      margin-top: var(--space-large);
      @include mq(tb) {
        display: none;
      }
      a {
        text-align: center;
      }
    }
  }
}
