@charset "UTF-8";

@import "mixins";

.navigation.newslist {
  margin-top: var(--margin-top-small);
  @include mq(tbl) {
    margin-top: var(--margin-top-medium);
  }
  .inner {
    @include container;
    ul {
      border-bottom: 1px solid var(--color-border);
      li {
        border-top: 1px solid var(--color-border);
        a {
          display: block;
          padding: var(--space-small) 0;
          color: var(--color-text);
          @include mq(tbl) {
            display: flex;
            align-items: flex-start;
            gap: 25px;
            position: relative;
            padding: var(--space-large) var(--space-small);
          }
          .date {
            font-size: var(--font-size-xsmall);
            font-family: var(--font-family-number);
            @include mq(tbl) {
              width: 5.5em;
              font-size: var(--font-size-medium);
              margin-top: var(--space-xxxsmall);
              line-height: var(--line-height-medium);
            }
          }
          .cat {
            display: inline-block;
            text-align: center;
            background-color: var(--color-accent-weak);
            color: var(--color-text-on-accent);
            font-size: var(--font-size-xxsmall);
            padding: var(--space-xxsmall) var(--space-xsmall);
            margin-left: var(--space-xsmall);
            @include mq(tbl) {
              width: 10em;
              font-size: var(--font-size-xsmall);
              min-width: var(--min-width-newslist-category);
              font-weight: var(--font-weight-bold);
              margin: var(--space-xxsmall) 0 0 0;
              padding: var(--space-xxsmall) var(--space-xxsmall);
            }
          }
          h2 {
            position: relative;
            margin-top: var(--space-xsmall);
            font-size: var(--font-size-small);
            line-height: var(--line-height-medium);
            @include mq(tbl) {
              margin: 0;
              flex: 1;
              font-size: var(--font-size-medium);
              @include hoverColor;
            }
          }
        }
      }
    }
  }
}
