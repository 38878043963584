@charset "UTF-8";

@import "mixins";

.navigation.articlelist {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .list {
      margin-top: var(--space-xxlarge);
      @include mq(tbl) {
        @include grid(2,var(--gap-articlelist-col-pc),var(--gap-articlelist-row-pc));
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: var(--space-xlarge);
        gap: var(--gap-articlelist-inner);
        @include mq(tbl) {
          margin-top: 0;
          gap: var(--gap-articlelist-inner-pc);
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          flex: 0 1 auto;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: var(--width-articlelist-image);
            @include mq(tbl) {
              width: var(--width-articlelist-image-pc);
              @include hoverOp;
            }
            img {
              aspect-ratio: var(--aspect-ratio-articlelist);
              object-fit: cover;
              &.default {
                // border: 1px solid var(--color-border);
              }
            }
          }
        }
        .desc {
          flex: 1;
          .date {
            font-size: var(--font-size-xsmall);
            font-weight: var(--font-weight-bold);
            font-family: var(--font-family-number);
            @include mq(tbl) {
              font-size: var(--font-size-small);
            }
          }
          .cat {
            margin-top: var(--space-xsmall);
            @include mq(tbl) {
              margin-top: var(--space-small);
            }
            a {
              display: inline-block;
              margin: 0 var(--space-xxsmall) var(--space-xxsmall) 0;
              font-size: var(--font-size-xxsmall);
              background-color: var(--color-surface);
              border-radius: 0;
              padding: var(--space-xxsmall) var(--space-xsmall);
              text-align: center;
              color: var(--color-text);
              text-decoration: none;
              border: 2px solid var(--color-brand-weak);
              @include mq(tbl) {
                font-size: var(--font-size-xsmall);
                padding: var(--space-xxsmall) var(--space-xsmall);
                margin: 0 var(--space-xsmall) var(--space-xxsmall) 0;
                transition: border var(--transition-speed-fast) var(--bezier);
                &:hover {
                  border-color: var(--color-brand-weaker);
                }
              }
            }
          }
          .title {
            margin-top: var(--space-xsmall);
            font-size: var(--font-size-medium);
            font-weight: var(--font-weight-bold);
            line-height: var(--line-height-medium);
            position: relative;
            @include mq(tbl) {
              @include hoverColor();
              margin-top: 10px;
              font-size: var(--font-size-large);
            }
            a {
              color: inherit;
              text-decoration: none;
            }
          }
          .summary {
            font-size: var(--font-size-xsmall);
            line-height: var(--line-height-medium);
            margin-top: var(--space-xxsmall);
            color: var(--color-text-weaker);
            @include mq(tbl) {
              font-size: var(--font-size-small);
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
