@charset "UTF-8";

@import "mixins";

.component.type_heading {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  &.large {
    .inner {
      @include container;
      .heading {
        padding-top: var(--space-xxxsmall);
        padding-left: var(--space-xxlarge);
        position: relative;
        font-size: var(--font-size-large);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-heading);
        @include mq(tbl) {
          padding-top: var(--space-xxxsmall);
          padding-left: var(--space-xxxlarge);
          font-size: var(--font-size-xxlarge);
        }
        &::before {
          @include pd;
          background-color: var(--color-third);
          width: 36px;
          height: 36px;
          left: 0;
          top: 0;
          border-radius: var(--border-radius-circle);
          @include mq(tbl) {
            width: 46px;
            height: 46px;
          }
        }
      }
    }
  }
  &.medium {
    .inner {
      @include container;
      .heading {
        @include headingMedium;
      }
    }
  }
  .anchor + & {
    // margin-top: 20px;
    @include mq(tbl) {
      // margin-top: 45px;
    }
  }
}
