@charset "UTF-8";

@import "mixins";

$header-store:true;

body:has(.toppage.visual) header {
  background-color: transparent;
  &::before {
    transform: translateY(-200%);
    transition: transform 2s cubic-bezier(0.25, 1, 0.5, 1);
  }
  &.is-down {
    transform: translateY(0);
  }
  &.is-scroll {
    &::before {
      filter: none;
    }
  }
  .inner {
    @include mq(tbl){
      transition: transform 2s cubic-bezier(0.25, 1, 0.5, 1);
      transform: translateY(-200%);
    }
    #logo {
      opacity: 0;
    }
    #header_botton_01,#header_botton_02 {
      transition: transform 2s cubic-bezier(0.25, 1, 0.5, 1);
      transform: translateY(-200%);
      @include mq(tbl){
        transform: translateY(0);
      }
    }
  }
  &.animation-fin {
    &::before {
      transform: translateY(0);
    }
    .inner {
      @include mq(tbl){
        transform: translateY(0);
      }
      #header_botton_01,#header_botton_02 {
        transform: translateY(0);
      }
    }
  }
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: var(--height-header);
  background-color: var(--color-bg-header);
  transition: transform var(--transition-speed) var(--bezier), background var(--transition-speed) var(--bezier);
  @include mq(tbl){
    height: var(--height-header-pc);
  }
  &:before {
    @include pd;
    width: 100%;
    z-index: 1002;
    background-color: var(--color-bg-header);
    height: var(--height-header);
    transition: filter var(--transition-speed) var(--bezier);
    @include mq(tbl){
      height: var(--height-header-pc);
    }
  }
  &.is-scroll {
    &:before {
      filter: var(--drop-shadow-header);
    }
  }
  &.is-down {
    transform: translateY(-120%);
  }
  .toppage & {
    background-color: var(--color-bg-header-toppage);
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1003;
    height: var(--height-header);
    @if $header-store {
      padding-right: var(--height-header);
    }
    @include mq(tbl){
      padding-right: 20px;
      justify-content: flex-start;
      height: var(--height-header-pc);
    }
    #logo {
      position: relative;
      margin-right: auto;
      z-index: 1002;
      padding: var(--padding-header-logo);
      @include mq(tbl){
        padding: var(--padding-header-logo-pc);
        margin-right: 0;
      }
      a {
        display: block;
        svg, img {
          &.sp {
            width: var(--width-header-logo);
            transition: transform var(--transition-speed) var(--bezier);
            transform-origin: left top;
            @include mq(tbl){
              display: none;
            }
          }
          &.pc {
            transition: transform var(--transition-speed) var(--bezier);
            transform-origin: left top;
            display: none;
            @include mq(tbl){
              display: inline-block;
              width: 150px;
            }
            @include mq(pc){
              width: var(--width-header-logo-pc);
            }
          }
        }
      }
    }
    #gnav {
      display: none;
      position: fixed;
      top: var(--height-header);
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1001;
      background-color: var(--color-menu-bg);
      padding: var(--space-medium) var(--padding-sp) var(--space-large);
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      transform: translateX(100%);
      transition: transform var(--transition-speed) var(--bezier);
      display: flex;
      flex-direction: column;
      @include mq(tbl){
        position: static;
        display: block;
        padding: 0 var(--space-medium) 0 0;
        background-color: transparent;
        transform: translateX(0);
        overflow: visible;
        align-self: stretch;
        margin-left: auto;
      }
      @include mq(pc){
        padding: 0 var(--space-large) 0 0;
      }
      &.show {
        transform: translateX(0);
      }
      .main {
        @include mq(tbl) {
          display: flex;
          height: 100%;
        }
        li {
          border-bottom: 1px solid var(--color-accent-weak);
          position: relative;
          @include mq(tbl){
            border-bottom: none;
          }
          &.top {
            @include mq(tbl) {
              display: none;
            }
          }
          &.active {
            &:after {
              @include mq(tbl) {
                @include pd;
                left: var(--space-small);
                right: var(--space-small);
                bottom: 0;
                height: var(--space-xxsmall);
                background-color: var(--color-accent);
              }
              @include mq(pc) {
                left: var(--space-medium);
                right: var(--space-medium);
              }
            }
          }
          a {
            display: block;
            padding: var(--space-small) 0;
            font-size: var(--font-size-small);
            line-height: var(--line-height-small);
            color: var(--color-text-on-accent);
            @include mq(spl) {
              padding: var(--space-small) 0;
              line-height: 1;
            }
            @include mq(tbl) {
              padding: var(--space-xsmall);
              font-size: var(--font-size-small);
              font-weight: var(--font-weight-bold);
              color: var(--color-text);
              @include hoverColor(var(--color-brand-weak));
              height: 100%;
              display: flex;
              align-items: center;
            }
            @include mq(pc) {
              padding: var(--space-medium);
              font-size: var(--font-size-medium);
            }
            .toppage & {
              color: var(--color-text-on-accent);
              @include mq(tbl) {
                color: var(--color-text-on-accent);
              }
            }
            span {
              position: relative;
              padding-left: 0;
              @include mq(tbl){
                padding: 0;
              }
            }
          }
        }
      }
    }
    #header_botton_01, #header_botton_02 {
      height: var(--height-header);
      width: var(--width-header-icon);
      padding: 0;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mq(tbl) {
        display: flex;
        height: 40px;
        width: auto;
        padding-left: var(--space-medium);
        padding-right: var(--space-medium);
        background-color: transparent;
        @include hoverBGColor(var(--color-brand-weak));
      }
      i {
        display: block;
        padding: 0 0 var(--space-xxxsmall);
        @include mq(tbl) {
          display: none;
        }
        img {
          width: var(--width-header-icon-image);
          @include mq(tbl) {
            width: var(--width-header-icon-image-pc);
          }
        }
      }
      span {
        display: block;
        color: var(--color-text-on-accent);
        font-size: var(--font-size-xxxsmall);
        white-space: nowrap;
        font-weight: var(--font-weight-bold);
        transform: scale(.7);
        @include mq(tbl) {
          font-size: var(--font-size-xsmall);
          transform: scale(1);
        }
        @include mq(pc) {
          font-size: var(--font-size-small);
        }
      }
    }
    #header_botton_01 {
      background-color: var(--color-brand-weak);
      @include mq(tbl) {
        @include hoverBGColor(var(--color-brand));
      }
      @include mq(tbl) {
        margin-right: 15px;
      }
    }
    #header_botton_02 {
      background-color: var(--color-third);
      @include mq(tbl) {
        @include hoverBGColor(var(--color-third-strong));
      }
      span {
        color: var(--color-text-weak);
      }
    }
    #menuButton {
      position: absolute;
      right: 0;
      top: 0;
      width: var(--height-header);
      height: var(--height-header);
      cursor: pointer;
      z-index: 1002;
      @include mq(tbl){
        display: none;
      }
      span {
        background-color: var(--color-menu-bar);
        width: var(--width-menu-bar);
        height: var(--height-menu-bar);
        position: absolute;
        left: calc((var(--height-header) - var(--width-menu-bar)) / 2);
        &.top {
          top: 21px;
          transition: transform var(--transition-speed-fast) var(--bezier), background var(--transition-speed-fast) var(--bezier);
        }
        &.middle, &.back {
          top: 28px;
          transform-origin: center;
          transition: transform var(--transition-speed-fast) var(--bezier), background var(--transition-speed-fast) var(--bezier);
        }
        &.bottom {
          top: 35px;
          transition: transform var(--transition-speed-fast) var(--bezier), background var(--transition-speed-fast) var(--bezier);
        }
      }
      &.show {
        background-color: var(--color-menu-bg);
        span {
          background-color: var(--color-menu-bar-active);
          &.top {
            transform: translateX(50%);
            background-color: transparent;
          }
          &.bottom {
            transform: translateX(-50%);
            background-color: transparent;
          }
          &.middle {
            transform: rotate(45deg) scaleX(1);
            transition: transform var(--transition-speed-fast) var(--bezier) var(--transition-speed-fast);
          }
          &.back {
            transform: rotate(-45deg) scaleX(1);
          }
        }
      }
    }
  }
}
