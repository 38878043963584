@charset "UTF-8";

@import "mixins";

.component.type_table {
  margin-top: var(--margin-top-medium);
  @include container;
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    .table_set {
      position: relative;
      overflow: auto;
      &.type_01 {
        table {
          border: none;
          th, td {
            border: none;
            padding: 0;
          }
        }
      }
      &.type_02 {
        table {

        }
      }
      &.type_03 {
        table {
          th {
            background-color: var(--color-cell-bg);
          }
        }
      }
      &.type_04 {
        table {

        }
        th {
          background-color: var(--color-cell-bg-dark);
          color: var(--color-text-on-accent);
        }
      }
      &.type_05 {
        table {
          th {
            // background-color: var(--color-brand);
          }
        }
      }
      &.type_06 {
        table {
          border-left: 1px solid var(--color-border);
          tr {
            &:nth-child(odd){
              background-color: var(--color-cell-bg);
            }
            &:nth-child(even){
              background-color: var(--color-surface);
            }
            th, td {
              border-right: 1px solid var(--color-border);
            }
          }
        }
      }
      &.type_07 {
        table {
          border-left: 1px solid var(--color-border);
          tr {
            &:nth-child(odd){
              background-color: var(--color-cell-bg);
            }
            &:nth-child(even){
              background-color: var(--color-surface);
            }
            th, td {
              font-weight: var(--font-weight-normal);
              border-right: 1px solid var(--color-border);
            }
          }
        }
      }
      &.compact {
        table {
          th,td {
            padding: var(--space-xsmall) var(--space-xsmall);
            line-height: var(--line-height-small);
          }
        }
      }
      &.fixed {
        table {
          @include mq(tbl) {
            table-layout: fixed;
          }
        }
      }
      &.fix_left {
        table {
          th {
            position: sticky;
            left: 0;
            /*
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              top: 0;
              bottom:0;
              height: 100%;
              background-color: #E7E5DF;
              width: 2px;
            }
            */
          }
        }
      }
      table {
        border-collapse: collapse;
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        width: 100%;
        th,td {
          border-top: 1px solid var(--color-border);
          vertical-align: top;
          padding: var(--space-medium) var(--space-medium);
          min-width: var(--min-width-cell);
          font-size: var(--font-size-small);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            font-size: var(--font-size-medium);
          }
          &.left {
            text-align: left;
          }
          &.center {
            text-align: center;
          }
          &.right {
            text-align: right;
          }
          &.min {
            min-width: auto;
          }
          .p_button {
            margin-top: 15px;
          }
        }
        th {
          vertical-align: middle;
          font-weight: var(--font-weight-bold);
        }
      }
    }
    .table_overflow {
      padding-top: 30px;
      position: sticky;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      &:before {
        content: '';
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px auto;
        background-image: url(/images/swipe.svg);
        width: 20px;
        height: 22px;
        margin-left: auto;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
