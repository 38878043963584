@charset "UTF-8";

@import "mixins";

.component.type_member {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    @include grid(1,var(--gap-member-col),0);
    @include mq(tb) {
      @include grid(2,26px,26px);
    }
    @include mq(tbl) {
      @include grid(3,var(--gap-member-col-pc),var(--gap-member-row-pc));
    }
    .unit {
      @include mq(tb) {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 4;
        gap: 0;
      }
      .photo {
        img {
          aspect-ratio: var(--aspect-ratio-member);
          object-fit: cover;
        }
      }
      .info {
        margin-top: var(--space-medium);
        @include mq(tbl) {
          margin-top: var(--space-large);
        }
        .position {
          font-size: var(--font-size-xsmall);
          font-weight: var(--font-weight-bold);
          line-height: var(--line-height-medium);
          margin-bottom: var(--space-xxsmall);
          text-align: center;
          @include mq(tbl) {
            font-size: var(--font-size-small);
          }
        }
        h3 {
          font-size: var(--font-size-large);
          line-height: var(--line-height-medium);
          font-weight: var(--font-weight-heading);
          font-family: var(--font-family-heading);
          text-align: center;
          @include mq(tbl) {
            font-size: var(--font-size-xlarge);
            line-height: var(--line-height-small);
          }
          i {
            font-size: var(--font-size-xxsmall);
            font-weight: var(--font-weight-normal);
            line-height: var(--line-height-small);
            display: block;
            @include mq(tbl) {
              font-size: var(--font-size-xsmall);
            }
          }
        }
        .profile {
          color: var(--color-text-weaker);
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-medium);
          margin-top: var(--space-small);
          @include mq(tbl) {
            font-size: var(--font-size-small);
            margin-top: var(--space-medium);
          }
        }
      }
      .desc {
        border-top: 1px solid var(--color-border);
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        margin: var(--space-medium) 0 0;
        padding: var(--space-medium) 0 0 ;
        @include mq(tbl) {
          font-size: var(--font-size-small);
        }
        p {
          padding-bottom: var(--space-small);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .button {
        text-align: center;
        margin-top: var(--space-large);
        @include mq(tbl) {
          margin-top: var(--space-xlarge);
        }
      }
    }
  }
}
