@charset "UTF-8";
@import "mixins";

.toppage.visual {
  overflow: hidden;
  padding-bottom: var(--margin-top-xlarge);
  padding-left: calc(50% - (295px / 2));
  padding-right: calc(50% - (295px / 2));
  @include mq(tb) {
    padding-left: calc(50% - (520px / 2));
    padding-right: calc(50% - (520px / 2));
  }
  @include mq(tbl) {
    padding-left: calc(50% - (754px / 2));
    padding-right: calc(50% - (754px / 2));
  }
  + .component.type_wysiwyg,
  + .toppage.lead {
    margin-top: 0;
  }
  &.is-inview {
    .logo {
      .mark {
        transform: translate(0, 0);
      }
      .type {
        img {
          transform: translateX(0);
        }
      }
    }
    .headline {
      h1 {
        span {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
    #sec01 {
      .title {
        p {
          &:before {
            transform: translateY(100%);
          }
        }
      }
      .line {
        &:before {
          transform: translateX(-100%);
        }
      }
    }
    #sec02 {
      .marker_wrap {
        .marker {
          transform: translateY(0);
        }
      }
      .title {
        p {
          &:before {
            transform: translateY(100%);
          }
        }
      }
      .line {
        &:before {
          transform: translateX(100%);
        }
      }
    }
    #sec03 {
      .title {
        p {
          &:before {
            transform: translateY(100%);
          }
        }
      }
      .arrows {
        transform: translate(300px, -600px) scale(3);
        opacity: 0;
        @include mq(tb) {
          transform: translate(450px, -900px) scale(3);
        }
        @include mq(tbl) {
          transform: translate(600px, -1200px) scale(3);
        }
        .arrow {
          opacity: 1;
          &.arrow-left {
            transform: translate(0, 0) scale(1);
          }
          &.arrow-right {
            transform: translate(0, 0) scale(1);
          }
          &.arrow-bottom {
            transform: translate(0, 0) scale(1);
          }
        }
      }
      .arrows_bg {
        opacity: 1;
      }
    }
  }
  .logo {
    position: fixed;
    z-index: 1001;
    top: 16px;
    left: 15px;
    height: 30px;
    display: flex;
    align-items: flex-start;
    @include mq(tb){
      top: 23px;
      left: 29px;
      height: 45px;
    }
    @include mq(tbl){
      height: 57px;
    }
    .mark {
      display: inline-block;
      transform: translate(-60px, 60px);
      transition: transform 1.6s cubic-bezier(0.25, 1, 0.5, 1) 6.6s;
      @include mq(tb){
        transform: translate(-160px, 160px);
      }
      img {
        height: 30px;
        @include mq(tb){
          height: 45px;
        }
        @include mq(tbl){
          height: 57px;
        }
      }
    }
    .type {
      display: inline-block;
      margin-left: 8px;
      position: relative;
      overflow: hidden;
      @include mq(tb){
        margin-left: 15px;
      }
      img {
        height: 29px;
        transition: transform 1.6s cubic-bezier(0.25, 1, 0.5, 1) 7.6s;
        transform: translateX(-120%);
        @include mq(tb){
          height: 43px;
        }
        @include mq(tbl){
          height: 55px;
        }
      }
    }
  }
  .headline {
    position: absolute;
    left: 0;
    right: 0;
    top: 120px;
    padding-left: calc(50% - (295px / 2));
    padding-right: calc(50% - (295px / 2));
    @include mq(tb) {
      top: 150px;
      padding-left: calc(50% - (520px / 2));
      padding-right: calc(50% - (520px / 2));
    }
    @include mq(tbl) {
      top: 270px;
      @include container;
    }
    h1 {
      font-size: 2rem;
      letter-spacing: 2px;
      font-weight: var(--font-weight-bold);
      line-height: 2.2;
      color: #575757;
      @include mq(tbl) {
        letter-spacing: 4px;
        font-size: 3rem;
        line-height: 2.8;
      }
      span {
        display: inline-block;
        padding: 0 10px;
        position: relative;
        @include mq(tbl) {
          padding: 0 20px;
        }
        &::before {
          @include pd;
          z-index: -1;
          left: 0;
          right: 10px;
          top: 5px;
          bottom: 5px;
          background-color: var(--color-third);
          transform: scaleX(0);
          transform-origin: left top;
          transition: transform 1s var(--bezier) .6s;
          @include mq(tbl) {
            right: 20px;
            top: 10px;
            bottom: 10px;
          }
        }
      }
    }
  }
  .title {
    p {
      writing-mode: vertical-rl;
      position: relative;
      display: inline-block;
      font-size: 2.4rem;
      letter-spacing: 5px;
      overflow: hidden;
      font-weight: var(--font-weight-bold);
      @include mq(tbl) {
        font-size: 4rem;
        letter-spacing: 8px;
      }
      &:before {
        @include pd;
        width: 100%;
        height: 100%;
        background-color: var(--color-surface);
      }
    }
  }
  .line {
    width: 120px;
    height: 4px;
    background-color: var(--color-brand-weak);
    position: absolute;
    @include mq(tb) {
      width: 310px;
    }
    @include mq(tbl) {
      width: 520px;
    }
    &:before {
      @include pd;
      width: 100%;
      height: 100%;
      outline : 1px solid white;
      background-color: white;
    }
  }
}
.toppage.visual #sec01 {
  margin-top: 190px;
  position: relative;
  @include mq(tb) {
    margin-top: 130px;
  }
  @include mq(tbl) {
    margin-top: 100px;
  }
  .title {
    margin: 30px 25px 0 auto;
    text-align: right;
    @include mq(tbl) {
      margin: 78px 0 0 auto;
    }
    p {
      &::before {
        transition: transform 1.6s cubic-bezier(0.25, 1, 0.5, 1) 2.0s;
      }
    }
  }
  .line {
    right: 120px;
    top: 64px;
    transform-origin: right top;
    transform: rotateZ(-36deg);
    @include mq(tb) {
      right: 160px;
      top: 95px;
    }
    @include mq(tbl) {
      right: 200px;
      top: 154px;
    }
    &::before {
      transition: transform 1s cubic-bezier(0.25, 1, 0.5, 1) 2.0s;
    }
  }
  @keyframes rotate-animation {
    0% {
      transform: rotateX(-25deg) rotateY(-45deg);
    }
    25% {
      transform: rotateX(-25deg) rotateY(-135deg);
    }
    50% {
      transform: rotateX(-25deg) rotateY(-225deg);
    }
    75% {
      transform: rotateX(-25deg) rotateY(-315deg);
    }
    100% {
      transform: rotateX(-25deg) rotateY(-405deg);
    }
  }
  @keyframes dice-color {
    0% {
      background-color: #fff;
    }
    65% {
      background-color: #fff;
    }
    66% {
      background-color: var(--color-brand-weak);
    }
    100% {
      background-color: var(--color-brand-weak);
    }
  }
  .dice {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: 30px;
    width: 60px;
    height: 60px;
    transform-style: preserve-3d;
    transform: rotateX(-25deg) rotateY(-45deg);
    animation: rotate-animation 2s cubic-bezier(0.25, 1, 0.5, 1) forwards;
    @include mq(tb) {
      width: 90px;
      height: 90px;
    }
    @include mq(tbl) {
      width: 120px;
      height: 120px;
    }
  }
  .dice .item {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 4px solid var(--color-brand-weak);
    border-radius: 1px;
    width: 60px;
    height: 60px;
    background-color: #fff;
    font-size: 3rem;
    text-align: center;
    color: black;
    @include mq(tb) {
      width: 90px;
      height: 90px;
    }
    @include mq(tbl) {
      width: 120px;
      height: 120px;
    }
  }
  .dice .item:nth-child(1) {
    transform: translate3d(0, -30px, 0) rotateX(-90deg);
    @include mq(tb) {
      transform: translate3d(0, -45px, 0) rotateX(-90deg);
    }
    @include mq(tbl) {
      transform: translate3d(0, -60px, 0) rotateX(-90deg);
    }
  }
  .dice .item:nth-child(2) {
    transform: translate3d(0, 0, 30px);
    @include mq(tb) {
      transform: translate3d(0, 0, 45px);
    }
    @include mq(tbl) {
      transform: translate3d(0, 0, 60px);
    }
  }
  .dice .item:nth-child(3) {
    transform: translate3d(30px, 0, 0) rotateY(90deg);
    animation: dice-color 1.8s linear forwards;
    @include mq(tb) {
      transform: translate3d(45px, 0, 0) rotateY(90deg);
    }
    @include mq(tbl) {
      transform: translate3d(60px, 0, 0) rotateY(90deg);
    }
  }
  .dice .item:nth-child(4) {
    transform: translate3d(-30px, 0, 0) rotateY(-90deg);
    @include mq(tb) {
      transform: translate3d(-45px, 0, 0) rotateY(-90deg);
    }
    @include mq(tbl) {
      transform: translate3d(-60px, 0, 0) rotateY(-90deg);
    }
  }
  .dice .item:nth-child(5) {
    transform: translate3d(0, 0, -30px) rotateY(180deg);
    @include mq(tb) {
      transform: translate3d(0, 0, -45px) rotateY(180deg);
    }
    @include mq(tbl) {
      transform: translate3d(0, 0, -60px) rotateY(180deg);
    }
  }
  .dice .item:nth-child(6) {
    transform: translate3d(0, 30px, 0) rotateX(-90deg);
    @include mq(tb) {
      transform: translate3d(0, 45px, 0) rotateX(-90deg);
    }
    @include mq(tbl) {
      transform: translate3d(0, 60px, 0) rotateX(-90deg);
    }
  }
}
.toppage.visual #sec02 {
  margin-top: -120px;
  position: relative;
  @include mq(tb) {
    margin-top: -20px;
  }
  @include mq(tbl) {
    margin-top: -10px;
  }
  .title {
    margin: 20px 0 0 15px;
    @include mq(tb) {
      margin: 20px 0 0 30px;
    }
    @include mq(tbl) {
      transform: translateX(-50px);
    }
    p {
      &::before {
        transition: transform 1.6s cubic-bezier(0.25, 1, 0.5, 1) 3.5s;
      }
    }
  }
  .line {
    left: 80px;
    top: 80px;
    transform-origin: left top;
    transform: rotateZ(36deg);
    @include mq(tb) {
      left: 110px;
      top: 110px;
    }
    @include mq(tbl) {
      left: 140px;
      top: 160px;
    }
    &::before {
      transition: transform 1.4s cubic-bezier(0.25, 1, 0.5, 1) 3.5s;
    }
  }
  .marker_wrap {
    overflow: hidden;
    .marker {
      width: 55px;
      transform: translateY(140%);
      transition: transform 1.2s cubic-bezier(0.25, 1, 0.5, 1) 2.6s;
      @include mq(tb) {
        width: 82px;
      }
      @include mq(tbl) {
        width: 110px;
      }
      .back{
        fill: url(#gradient);
        stroke: var(--color-brand-weak);
        stroke-width: 4;
        animation-fill-mode: forwards;
      }
      .front{
        fill: #FFFFFF;
        stroke: var(--color-brand-weak);
        stroke-width: 4;
      }
    }
  }
}
.toppage.visual #sec03 {
  position: relative;
  margin-top: -140px;
  @include mq(tb) {
    margin-top: -30px;
  }
  @include mq(tbl) {
    margin-top: 30px;
  }
  .title {
    margin: 0 90px 0 auto;
    text-align: right;
    transform: translateY(-30px);
    @include mq(tb) {
      margin: 0 140px 0 auto;
      transform: translateY(-60px);
    }
    @include mq(tbl) {
      margin: 0 240px 0 auto;
      transform: translateY(-100px);
    }
    p {
      &::before {
        transition: transform 1.6s cubic-bezier(0.25, 1, 0.5, 1) 4.8s;
      }
    }
  }
  .arrows {
    width: 112px;
    height: 100px;
    position: relative;
    margin: 0 0 0 auto;
    z-index: 2;
    transition: all 1.2s cubic-bezier(.9,-0.46,.17,1) 5.6s;
    @include mq(tb) {
      width: 168px;
      height: 150px;
    }
    @include mq(tbl) {
      width: 224px;
      height: 200px;
    }
    .arrow {
      width: 53px;
      position: absolute;
      opacity: 0;
      @include mq(tb) {
        width: 80px;
      }
      @include mq(tbl) {
        width: 107px;
      }
      &.arrow-left {
        left: 0;
        transform: translate(-150px, -150px) scale(20);
        transition: all .6s cubic-bezier(0.25, 1, 0.5, 1) 4.2s;
        @include mq(tb) {
          transform: translate(-225px, -225px) scale(20);
        }
        @include mq(tbl) {
          transform: translate(-300px, -300px) scale(20);
        }
      }
      &.arrow-right {
        right: 0;
        transform: translate(150px, -150px) scale(20);
        transition: all .6s cubic-bezier(0.25, 1, 0.5, 1) 4.4s;
        @include mq(tb) {
          transform: translate(-225px, -225px) scale(20);
        }
        @include mq(tbl) {
          transform: translate(-300px, -300px) scale(20);
        }
      }
      &.arrow-bottom {
        right: 22px;
        bottom: 0;
        transform: translate(0, 150px) scale(20);
        transition: all .6s cubic-bezier(0.25, 1, 0.5, 1) 4.6s;
        @include mq(tb) {
          transform: translate(0, 225px) scale(20);
          right: 33px;
        }
        @include mq(tbl) {
          transform: translate(0, 300px) scale(20);
          right: 44px;
        }
      }
    }
  }
  .arrows_bg {
    width: 112px;
    height: 100px;
    position: relative;
    margin: -100px 0 0 auto;
    opacity: 0;
    transition: all 1.6s cubic-bezier(.9,-0.66,.17,1) 4.2s;
    @include mq(tb) {
      width: 168px;
      height: 150px;
      margin: -150px 0 0 auto;
    }
    @include mq(tbl) {
      width: 224px;
      height: 200px;
      margin: -200px 0 0 auto;
    }
    .arrow {
      width: 53px;
      position: absolute;
      opacity: 1;
      @include mq(tb) {
        width: 80px;
      }
      @include mq(tbl) {
        width: 107px;
      }
      &.arrow-left {
        left: 0;
      }
      &.arrow-right {
        right: 0;
      }
      &.arrow-bottom {
        right: 22px;
        bottom: 0;
        @include mq(tb) {
          right: 33px;
        }
        @include mq(tbl) {
          right: 44px;
        }
      }
    }
  }
}
