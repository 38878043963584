@charset "UTF-8";

@import "mixins";

.component.type_text_lead {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .text {
      p {
        font-size: var(--font-size-large);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: var(--font-size-xxlarge);
        }
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
}
