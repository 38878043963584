@charset "UTF-8";

@import "mixins";

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: table;
    content: '';
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}


.static.slider_typeB {
  position: relative;
  height: 474px;
  letter-spacing: 1px;
  @include mq(tbl) {
    height: 684px;
  }
  &:before {
    @include pd;
    background-color: var(--color-surface-weaker);
    width: 40%;
    height: 220px;
    left: 0;
    bottom: 0;
    @include mq(tbl) {
      width: 36%;
      height: 100%;
    }
  }
  .unit {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    height: 474px;
    @include mq(tbl) {
      display: block;
      height: 684px;
    }
    > a {
      display: block;
      position: relative;
      z-index: 1;
    }
    .text {
      position: absolute;
      bottom: 62px;
      width: 100%;
      z-index: 10;
      @include mq(tbl) {
        bottom: 182px;
        left: 9%;
      }
      .textinner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      h1 {
        display: inline-block;
        background: var(--color-brand);
        padding: var(--space-small) var(--space-medium);
        font-family: var(--font-family-heading);
        font-size: var(--font-size-large);
        line-height: var(--line-height-medium);
        color: var(--color-text-on-accent);
        position: relative;
        overflow: hidden;
        margin-left: 20px;
        transform: translateY(20px);
        opacity: 0;
        transition: transform var(--transition-speed-slow) var(--bezier) .2s, opacity var(--transition-speed-slow) var(--bezier) .2s;
        @include mq(tbl) {
          padding: var(--space-medium) var(--space-xxxlarge);
          margin-left: 70px;
          font-size: var(--font-size-xxxlarge);
          color: var(--color-text-on-accent);
        }
        &:before {
          @include pd;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: var(--color-surface-weaker);
          transition: transform var(--transition-speed-slow) var(--bezier) .8s;
          transform-origin: right;
        }
      }
      p {
        background: var(--color-brand);
        padding: var(--space-xsmall) var(--space-medium);
        display: inline-block;
        color: var(--color-text-on-accent);
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        font-family: var(--font-family-heading);
        margin-top: 4px;
        position: relative;
        transform: translateY(20px);
        opacity: 0;
        transition: transform var(--transition-speed-slow) var(--bezier) .4s, opacity var(--transition-speed-slow) var(--bezier) .4s;
        @include mq(tbl) {
          padding: var(--space-small) var(--space-xxxlarge);
          font-size: var(--font-size-medium);
          margin-top: 8px;
        }
        &:before {
          @include pd;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-color: var(--color-surface-weaker);
          transition: transform var(--transition-speed-slow) var(--bezier) var(--transition-speed-slow);
          transform-origin: right;
        }
      }
      .button {
        text-align: center;
        border: none;
        a {
          background-color: var(--color-surface);
          color: var(--color-text-weak);
          display: inline-block;
          font-size: var(--font-size-xsmall);
          padding: var(--space-xsmall) var(--space-large);
          position: relative;
          margin-left: 135px;
          opacity: 0;
          transition: color var(--transition-speed-fast) var(--bezier), opacity var(--transition-speed-slow) var(--bezier) var(--transition-speed-slow);
          &:before {
            @include pd;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: var(--color-surface-weaker);
            transition: transform var(--transition-speed-slow) var(--bezier) var(--transition-speed-slow);
            transform-origin: right;
          }
          @include mq(tbl) {
            margin-left: 235px;
            padding: var(--space-medium) var(--space-xlarge);
            font-size: var(--font-size-medium);
            &:hover {
              color: var(--color-brand);
            }
          }
        }
      }
    }
    .bg {
      width: 87%;
      height: 433px;
      background-position: center;
      background-size: cover;
      transition: opacity .2s ease;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--color-deep);
      @include mq(tbl) {
        display: block;
        width: 81%;
        height: 548px;
      }
    }
    &.slick-active::before, &.is-active-next::before {
      opacity: 0;
      z-index: 0;
    }
    .bg.pc {
      display: none;
      @include mq(tbl) {
        display: block;
      }
    }
    .bg.sp {
      display: block;
      @include mq(tbl) {
        display: none;
      }
    }
    &.slick-now.slick-active {
      .text {
        .textinner {
          h1 {
            transform: translateY(0);
            opacity: 1;
            &:before {
              transform: scaleX(0);
            }
          }
          p {
            transform: translateY(0);
            opacity: 1;
            &:before {
              transform: scaleX(0);
            }
          }
          .button {
            a {
              opacity: 1;
              &:before {
                transform: scaleX(0);
              }
            }
            
          }
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 46px;
    z-index: 10;
    padding: 7px 18px;
    cursor: pointer;
    @include mq(tbl) {
      display: block !important;
      bottom: 97px;
      @include hoverOp(.7);
    }
    img {
      width: 10px;
      transform: rotate(180deg);
    }
    &.slick-next {
      left: auto;
      right: 0;
      border-left: 1px solid var(--color-border);
      img {
        transform: rotate(0deg);
      }
    }
  }
  .slick-dots {
    display: none !important;
    @include mq(tbl) {
      display: block !important;
      position: absolute;
      top: auto;
      bottom: 97px;
      right: 100px;
      @include slickdots(20px,3px);
    }
  }
}