@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image {
  margin-top: var(--margin-top-large);
  @include mq(tbl) {
    margin-top: var(--margin-top-xxlarge);
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      flex-direction: row-reverse;
      gap: var(--gap-2col-row-pc);
      position: relative;
      overflow: hidden;
    }
    .image {
      @include mq(tb) {
        width: var(--col-width-image);
      }
      img {
        aspect-ratio: var(--aspect-ratio-catchcopy-image);
        object-fit: cover;
        min-width: calc(50vw - (var(--gap-2col-row-pc) / 2));
        @include mq(pc) {
          min-width: calc(40vw - (var(--gap-2col-row-pc) / 2));
        }
      }
    }
    .text {
      margin-top: var(--space-xlarge);
      @include mq(tb) {
        margin-top: 0;
        width: var(--col-width-text);
      }
      h3 {
        @include headingMedium;
        display: inline-block;
      }
      .copy {
        h4 {
          margin-top: var(--space-large);
          font-size: var(--font-size-xlarge);
          line-height: var(--line-height-medium);
          font-weight: var(--font-weight-heading);
          font-family: var(--font-family-heading);
          @include mq(tbl) {
            font-size: var(--font-size-xxxlarge);
          }
        }
      }
      .desc {
        margin-top: var(--space-xlarge);
        @include mq(tbl) {
          margin-top: var(--space-xxxlarge);
        }
      }
      .button {
        margin-top: var(--space-large);
        text-align: left;
        @include mq(tbl) {
          margin-top: var(--space-xxlarge);
        }
        a {
          text-align: center;
        }
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tb) {
        flex-direction: row;
      }
      .image {
        @include mq(tb) {
          direction: rtl;
        }
      }
      /*
      .text {
        @include mq(tb) {
          text-align: right;
        }
        .desc {
          @include mq(tb) {
            text-align: left;
          }
        }
        .button {
          @include mq(tb) {
            text-align: right;
          }
        }
      }
      */
    }
  }
}
